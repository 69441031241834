import { constants, FILTER_LEASE_TYPES_PERSONAL } from '@/lib/constants'
import { useExperiment } from '@/lib/hooks/useExperiment'
import { addVatToPrice } from '@/lib/utilities/search'
import { addToDataLayer, getUrlReadyString, getVehicleHeroImage } from '@/lib/utilities/vehicle'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Head from 'next/head'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import removeMd from 'remove-markdown'

export const PageMetaData = ({
  title,
  description,
  pageUrl,
  canonical = null,
  image = null,
  collectionPage = null,
  noIndex = false,
  blogArticle = null,
  faqs = null,
  isHomePage = false,
  vehicle = null,
  leaseProfile = null,
  results = null,
}) => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const router = useRouter()
  const [experimentGroup] = useExperiment()
  const contentExists = !!leaseProfile && !!vehicle && !!leaseProfile.term && !!vehicle.id
  const url = `${process.env.NEXT_PUBLIC_URL}${pageUrl}`
  const [prevPageUrl, setPrevPageUrl] = useState('')
  const [prevTitle, setPrevTitle] = useState('')

  useEffect(() => {
    if (router.isReady && prevTitle !== title && prevPageUrl !== pageUrl) {
      setPrevTitle(title)
      setPrevPageUrl(pageUrl)
      addToDataLayer({
        event: 'virtual-page-view',
        pageUrl: `${process.env.NEXT_PUBLIC_URL}${pageUrl}`,
        pagePath: pageUrl,
        pageTitle: title,
        pageHostname: process.env.NEXT_PUBLIC_URL,
        experimentGroup,
      })
    }
  }, [pageUrl, title, router.isReady, experimentGroup, prevTitle, prevPageUrl])

  return (
    <Head>
      <meta
        name="viewport"
        content="initial-scale=1.0, width=device-width"
        key="viewport"
      />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={`${process.env.NEXT_PUBLIC_URL}/apple-icon-57x57.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={`${process.env.NEXT_PUBLIC_URL}/apple-icon-60x60.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={`${process.env.NEXT_PUBLIC_URL}/apple-icon-72x72.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={`${process.env.NEXT_PUBLIC_URL}/apple-icon-76x76.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={`${process.env.NEXT_PUBLIC_URL}/apple-icon-114x114.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={`${process.env.NEXT_PUBLIC_URL}/apple-icon-120x120.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={`${process.env.NEXT_PUBLIC_URL}/apple-icon-144x144.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={`${process.env.NEXT_PUBLIC_URL}/apple-icon-152x152.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${process.env.NEXT_PUBLIC_URL}/apple-icon-180x180.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={`${process.env.NEXT_PUBLIC_URL}/android-icon-192x192.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${process.env.NEXT_PUBLIC_URL}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href={`${process.env.NEXT_PUBLIC_URL}/favicon-96x96.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${process.env.NEXT_PUBLIC_URL}/favicon-16x16.png`}
      />
      <link
        rel="manifest"
        crossOrigin="anonymous"
        href={`${process.env.NEXT_PUBLIC_URL}/manifest.json`}
      />
      <link
        rel="shortcut icon"
        href={`${process.env.NEXT_PUBLIC_URL}/favicon.ico`}
      />
      <meta
        name="msapplication-TileColor"
        content="#ffffff"
        key="ms-tile-color"
      />
      <meta
        name="msapplication-TileImage"
        href={`${process.env.NEXT_PUBLIC_URL}/ms-icon-144x144.png`}
        key="ms-tile-image"
      />
      <meta
        name="theme-color"
        content="#ffffff"
        key="theme-color"
      />
      <meta
        name="google-site-verification"
        content="o7ymSALxCvA53q_2pxX9Q2U8MnP_1KO_2161Oj_faTQ"
        key="google-site-verification"
      />
      <meta
        property="fb:app_id"
        content="2133178003609549"
        key="fb-app-id"
      />
      <title>{title}</title>
      <meta
        name="title"
        content={title}
        key="title"
      />
      <meta
        name="description"
        content={description}
        key="description"
      />
      <meta
        name="url"
        content={url}
        key="url"
      />
      <link
        rel="canonical"
        href={canonical ? canonical : url}
        key="canonical"
      />
      <meta
        name="image"
        content={constants.imageHost.social}
        key="image"
      />
      <meta
        name="author"
        content="LeaseLoco"
        key="author"
      />

      <meta
        property="og:url"
        content={url}
        key="og:url"
      />
      <meta
        property="og:title"
        content={title}
        key="og:title"
      />
      <meta
        property="og:description"
        content={description}
        key="og:description"
      />
      <meta
        property="og:image"
        content={image ? image : constants.imageHost.social}
        key="og:image"
      />
      <meta
        property="og:site_name"
        content="LeaseLoco"
        key="og:site_name"
      />
      <meta
        property="og:type"
        content="article"
        key="og:type"
      />
      <meta
        property="article:author"
        content="https://www.facebook.com/leaseloco/"
        key="og:article:author"
      />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/leaseloco/"
        key="og:article:publisher"
      />

      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
      <meta
        name="twitter:site"
        content="@leaseloco"
        key="twitter:site"
      />
      <meta
        name="twitter:creator"
        content="@leaseloco"
        key="twitter:creator"
      />
      <meta
        name="twitter:title"
        content={title}
        key="twitter:title"
      />
      <meta
        name="twitter:description"
        content={description}
        key="twitter:description"
      />
      <meta
        name="twitter:image:src"
        content={image ? image : constants.imageHost.social}
        key="twitter:image:src"
      />
      {noIndex && (
        <meta
          name="robots"
          content="noindex"
          key="robots"
        />
      )}

      {isHomePage && (
        <script
          type="application/ld+json"
          key="schema-organization">
          {`
            {
              "@context" : "https://schema.org",
              "@type" : "Organization",
              "name" : "LeaseLoco",
              "url" : "https://www.leaseloco.com/",
              "sameAs" : [
                "https://www.facebook.com/leaseloco/",
                "https://twitter.com/leaseloco",
                "https://www.youtube.com/leaseloco",
                "https://www.instagram.com/leaseloco/"
              ]
            }
          `}
        </script>
      )}

      <script
        type="application/ld+json"
        key="schema-service">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Service",
            "provider": {
                "@type": "Organization",
                "name": "LeaseLoco",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "33 Arx Technology Centre, James Watt Avenue",
                    "postalCode": "G75 0QD",
                    "addressRegion": "Scotland",
                    "addressLocality": "Glasgow",
                    "addressCountry": "https://www.wikidata.org/wiki/Q145",
                    "name": "Postal Address",
                    "@id": "${url}"
                },
                "description": "${description}",
                "@id": "${url}"
            },
            "logo": "https://assets.leaseloco.com/logos/dark.svg",
            "brand": {
                "@type": "Organization",
                "name": "LeaseLoco"
            },
            "url": "${url}",
            "areaServed": "https://www.wikidata.org/entity/Q145",
            "description": "${description}",
            "name": "LeaseLoco",
            "@id": "${url}"
          }
        `}
      </script>

      {!!collectionPage && collectionPage.length > 0 && (
        <script
          type="application/ld+json"
          key="schema-collection-page">
          {`
            {
              "@context":"https://schema.org",
              "@type":"CollectionPage",
              "@id":"${url}",
              "mainEntity":{
                "@type":"ItemList",
                "itemListOrder":"ItemListOrderDescending",
                "numberOfItems":"${collectionPage.length}",
                "itemListElement": [
                  ${collectionPage.map(
            (vehicle, index) => `{
                      "@type":"ListItem",
                      "position":"${index + 1}",
                      "item":{
                        "@type": "Product",
                        "image": "${getVehicleHeroImage(vehicle.profileImage)}",
                        "name": "${vehicle.manufacturer} ${vehicle.model}",
                        "url": "${process.env.NEXT_PUBLIC_URL}/car-leasing/${getUrlReadyString(
              vehicle.manufacturer,
            )}/${getUrlReadyString(vehicle.model)}/${getUrlReadyString(
              vehicle.derivative,
            )}/${vehicle.vehicleId}/${vehicle.profile.leaseType}-${vehicle.profile.term}-${
              vehicle.profile.mileage
            }-${vehicle.profile.initialPaymentInMonths}-0",
                        "description": "${vehicle.manufacturer} ${vehicle.model} ${
              vehicle.derivative
            }",
                        "itemCondition": "https://schema.org/NewCondition",
                        "offers": {
                          "@type": "Offer",
                          "price": "${
              vehicle.profile.leaseType === FILTER_LEASE_TYPES_PERSONAL
                ? addVatToPrice(vehicle.profile.price)
                : vehicle.profile.price
            }",
                          "priceCurrency": "GBP",
                          "availability": "${
              vehicle.inStock
                ? 'https://schema.org/InStock'
                : 'https://schema.org/PreOrder'
            }",
                          "url": "${process.env.NEXT_PUBLIC_URL}/car-leasing/${getUrlReadyString(
              vehicle.manufacturer,
            )}/${getUrlReadyString(vehicle.model)}/${getUrlReadyString(
              vehicle.derivative,
            )}/${vehicle.vehicleId}/${vehicle.profile.leaseType}-${vehicle.profile.term}-${
              vehicle.profile.mileage
            }-${vehicle.profile.initialPaymentInMonths}-0",
                        },
                        "brand": {
                          "@type": "Organization",
                          "name": "${vehicle.manufacturer}"
                        },
                        "review": [
                          {
                            "@type": "Review",
                            "itemReviewed": {
                              "@type": "Offer",
                              "name": "${vehicle.manufacturer} ${vehicle.model} ${
              vehicle.derivative
            } (${vehicle.profile.term} Months, ${vehicle.profile.mileage} Miles and ${
              vehicle.profile.initialPaymentInMonths
            } Months Upfront)",
                            },
                            "reviewRating": {
                              "@type": "Rating",
                              "bestRating": "100",
                              "ratingValue": "${vehicle.rating}"
                            },
                            "author": {
                              "@type": "Organization",
                              "name": "LeaseLoco"
                            }
                          }
                        ]
                      }
                    }`,
          )}
                ]
              }
            }
          `}
        </script>
      )}
      {!!blogArticle && (
        <script
          type="application/ld+json"
          key="schema-blog-article">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type":"WebPage",
                "@id":"${url}"
              },
              "headline": "${blogArticle.title}",
              "description": "${
            blogArticle.metaDescription
              ? blogArticle.metaDescription
              : blogArticle.description.replace(/<[^>]*>?/gm, '')
          }",
              "datePublished": "${dayjs(blogArticle.pubDate).tz('Europe/London').format()}",
              "datemodified": "${dayjs(blogArticle.pubDate).tz('Europe/London').format()}",
              "image": {
                "@type": "imageObject",
                "url": "${blogArticle.thumbnail}",
                "height": "600",
                "width": "800"
              },
              "publisher": {
                "@type": "Organization",
                "name": "LeaseLoco",
                "logo": {
                  "@type": "imageObject",
                  "url": "https://assets.leaseloco.com/logos/dark.svg"
                }
              },
              "author": {
                "@type": "Person",
                "name": "${blogArticle.author}",
                "jobTitle": "Head of Content"
              },
              "sharedContent": [
                {
                  "@type":"WebPage",
                  "headline": "${blogArticle.title}",
                  "url": "${url}",
                  "author": {
                    "@type":"Person",
                    "name": "${blogArticle.author}",
                    "jobTitle": "Head of Content"
                  }
                }
              ],
              "articleBody": "${removeMd(blogArticle.body).replace(
            constants.regExp.findNewLines,
            ' ',
          )}"
            }
          `}
        </script>
      )}
      {!!faqs && (
        <script
          type="application/ld+json"
          key="schema-faqs">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [${faqs.map(faq => {
            return JSON.stringify({
              '@type': 'Question',
              name: `${faq.title}`,
              acceptedAnswer: {
                '@type': 'Answer',
                text: `${removeMd(faq.body).replace(constants.regExp.findNewLines, ' ')}`,
              },
            })
          })}]
            }
          `}
        </script>
      )}
      {contentExists && !!results && results.length > 0 && (
        <script
          type="application/ld+json"
          key="schema-product">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "${vehicle.manufacturerName} ${vehicle.modelName} ${
            vehicle.derivativeName
          } (${leaseProfile.term} Months, ${leaseProfile.mileage} Miles and ${
            leaseProfile.initialPaymentInMonths
          } Months Upfront)",
              "image": "${image}",
              "description": "${description}",
              "itemCondition": "https://schema.org/NewCondition",
              "brand": {
                "@type": "Organization",
                "name": "${vehicle.manufacturerName}"
              },
              "offers": {
                "@type": "AggregateOffer",
                "priceCurrency": "GBP",
                "highPrice": "${
            leaseProfile.leaseType === FILTER_LEASE_TYPES_PERSONAL
              ? addVatToPrice(results[results.length - 1].vehiclePrice.monthlyPayment)
              : results[results.length - 1].vehiclePrice.monthlyPayment.toFixed(2)
          }",
                "lowPrice": "${
            leaseProfile.leaseType === FILTER_LEASE_TYPES_PERSONAL
              ? addVatToPrice(results[0].vehiclePrice.monthlyPayment)
              : results[0].vehiclePrice.monthlyPayment.toFixed(2)
          }",
                "offerCount": "${results.length}",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "${
            leaseProfile.inStock === 1
              ? 'https://schema.org/InStock'
              : 'https://schema.org/PreOrder'
          }",
                "offers": [
                  ${results.map(price => {
            return JSON.stringify({
              '@type': 'Offer',
              // "url": router.asPath + "/" + price.vehiclePrice.id,
              priceCurrency: 'GBP',
              price: `${
                leaseProfile.leaseType === FILTER_LEASE_TYPES_PERSONAL
                  ? addVatToPrice(price.vehiclePrice.monthlyPayment)
                  : price.vehiclePrice.monthlyPayment.toFixed(2)
              }`,
              seller: {
                '@type': 'Organization',
                name: `${price.supplier.name}`,
              },
            })
          })}
                ]
              }
            }
          `}
        </script>
      )}
      {contentExists && vehicle.type === 1 && (
        <script
          type="application/ld+json"
          key="schema-vehicle">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "Car",
              "name": "${vehicle.manufacturer} ${vehicle.model} ${vehicle.derivative}",
              "image": "${image}",
              "itemCondition": "https://schema.org/NewCondition",
              "manufacturer": {
                "@type": "Organization",
                "name": "${vehicle.manufacturer}"
              },
              "fuelType": "${vehicle.fuelType}",
              "vehicleConfiguration": "${vehicle.derivative}",
              "vehicleTransmission": "${vehicle.transmissionType}",
              "bodyType": "${vehicle.bodyStyle}",
              "offers": [
                {
                  "@type": "Offer",
                  "priceCurrency": "GBP",
                  "price": "${
            !!results && results.length > 0
              ? leaseProfile.leaseType === FILTER_LEASE_TYPES_PERSONAL
                ? addVatToPrice(results[0].vehiclePrice.monthlyPayment)
                : results[0].vehiclePrice.monthlyPayment.toFixed(2)
              : 0
          }",
                  "seller": {
                    "@type": "Organization",
                    "name": "${!!results && results.length > 0 && results[0].supplier.name}"
                  },
                  "url": "${router.asPath}",
                  "itemCondition": "https://schema.org/NewCondition"
                }
              ]
            }
          `}
        </script>
      )}
    </Head>
  )
}

PageMetaData.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  pageUrl: PropTypes.string.isRequired,
  image: PropTypes.string,
  collectionPage: PropTypes.array,
  blogArticle: PropTypes.object,
  faqs: PropTypes.array,
  noIndex: PropTypes.bool,
  canonical: PropTypes.string,
  isHomePage: PropTypes.bool,
  vehicle: PropTypes.object,
  leaseProfile: PropTypes.object,
  results: PropTypes.array,
}
